<template>
  <div>
    <section class="py-3 px-5 page-banner">
      <router-link to="/languages" v-if="activeTabName != 'Multilingual support'" class="go-back-link"><BIconArrowLeftShort />Back to languages</router-link>
      <h3 class="page-header py-3">{{ activeTabName }}</h3>
      <b-row class="justify-content-between">
        <b-col cols="12" class="col-sm-6">
          <b-nav role="navigation">
            <b-nav-item to="/languages/languages-categories/languages" exact exact-active-class="active">Languages</b-nav-item>
          </b-nav>
        </b-col>
      </b-row>
    </section>
    <section class="py-4 px-5">
      <b-form-row v-if="restrictAddLanguageToMultipleCity">
        <b-col>
          <b-alert show variant="danger">{{ DISPLAY_MESSAGES.MULTIPLE_CITIES_LANGUAGE_ERROR }}</b-alert>
        </b-col>
      </b-form-row>
      <router-view v-else-if="isMultilingualEnabled"></router-view>
      <b-form-row v-else>
        <b-col>
          <b-alert show variant="danger">{{ DISPLAY_MESSAGES.MULTILINGUAL_ENABLE_ERROR }}</b-alert>
        </b-col>
      </b-form-row>
    </section>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { BIconArrowLeftShort } from 'bootstrap-vue'
import { DISPLAY_MESSAGES } from '@/utilities/constants'
export default {
  name: 'LanguagesCategories',
  data() {
    return {
      activeTabName: '',
      DISPLAY_MESSAGES: DISPLAY_MESSAGES,
    }
  },
  components: {
    BIconArrowLeftShort,
  },
  created() {
    this.setActivatedTab()
  },
  computed: {
    restrictAddLanguageToMultipleCity() {
      return this.selectedTenant.length > 1 || this.selectedTenant.some((tenant) => tenant.tenantID === 0)
    },
    isMultilingualEnabled() {
      return this.selectedTenant && this.selectedTenant.length ? this.selectedTenant[0].isMultilingualEnabled : false
    },
    ...mapState({
      selectedTenant: (state) => state.common.selectedTenant,
    }),
  },
  methods: {
    setActivatedTab() {
      if (this.$route.name === 'Languages-list') {
        this.activeTabName = 'Multilingual support'
      } else if (this.$route.name === 'Add-language') {
        this.activeTabName = 'Add language'
      } else if (this.$route.name === 'Edit-language') {
        this.activeTabName = 'Edit language'
      } else {
        this.activeTabName = ''
      }
    },
  },
  watch: {
    $route() {
      this.setActivatedTab()
    },
  },
}
</script>
<style lang="scss" scoped></style>
